<template>
  <v-container
    fluid
    class="pb-8">
    <v-simple-table
      :loading="loading"
      id="productTable"
      class="elevation-1 mt-4"
      fixed-header
      :height="tableHeight">
      <thead>
        <tr>
          <th class="px-2 text-left">Verfügbar</th>
          <th class="px-2 text-left">Produktbild</th>
          <th class="px-2 text-left">Produkt</th>
          <th class="px-2 text-left">Bereitstellung</th>
          <th class="px-2 text-left">Bereitstellung überschreiben</th>
          <th class="px-2 text-left">Pro Tag</th>
          <th class="px-2 text-left">Pro Tag überschreiben</th>
          <th class="px-2 text-left">Kaution</th>
          <th class="px-2 text-left">Kaution überschreiben</th>
        </tr>
      </thead>
      <tr
        v-for="product in products"
        :key="product.name"
        style="height: 90px"
        class="-clickable">
        <td class="px-2 pl-4">
          <v-switch
            v-model="product.active"></v-switch>
        </td>
        <td class="px-2">
          <v-img
            class="productImage"
            max-height="80"
            max-width="80"
            :src="product.image"
          ></v-img>
        </td>
        <td class="px-2">{{ product.name }}</td>
        <td class="px-2">
          {{ product.defaultPrices[0].price | euro }}
        </td>
        <td class="px-2">
          <v-text-field
            v-model="product.customPrices[0].price"
            append-icon="euro"
            :placeholder="String(product.defaultPrices[0].price)"
            single-line
            :hint="'Brutto (inkl ' + product.vatPercentage + '% USt.)'"></v-text-field>
        </td>
        <td class="px-2">
          {{ product.defaultPrices[1].price | euro }}
        </td>
        <td class="px-2">
          <v-text-field
            v-model="product.customPrices[1].price"
            append-icon="euro"
            :placeholder="String(product.defaultPrices[1].price)"
            single-line
            :hint="'Brutto (inkl ' + product.vatPercentage + '% USt.)'"></v-text-field>
        </td>
        <td class="px-2">
          {{ product.defaultPrices[2].price | euro }}
        </td>
        <td class="px-2 pr-4">
          <v-text-field
            v-model="product.customPrices[2].price"
            append-icon="euro"
            :placeholder="String(product.defaultPrices[2].price)"
            single-line></v-text-field>
        </td>
      </tr>
    </v-simple-table>
    <v-card class="s-bottomActionCard">
      <v-card-text>
        <v-layout>
          <v-flex shrink>
            <v-btn
              color="success"
              :loading="saving"
              @click="update">
              Speichern
            </v-btn>
          </v-flex>
          <v-spacer/>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import PricesAPI from '@/api/prices'
import dirtyState from '@/mixins/dirtyState'

export default {
  mixins: [dirtyState],
  props: {
    company: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      tableHeight: 500,
      headers: [
        {
          text: 'Verfügbar',
          value: 'active',
        },
        {
          text: 'Produktbild',
          sortable: false,
          value: 'image',
        },
        {
          text: 'Produkt',
          value: 'name',
        },
        {
          text: 'Bereitstellung',
          sortable: false,
          value: 'defaultPrices[0].price',
        },
        {
          text: 'Bereitstellung überschreiben',
          sortable: false,
          value: 'customPrices[0].price',
        },
        {
          text: 'Pro Tag',
          sortable: false,
          value: 'defaultPrices[1].price',
        },
        {
          text: 'Pro Tag überschreiben',
          sortable: false,
          value: 'customPrices[1].price',
        },
        {
          text: 'Kaution',
          sortable: false,
          value: 'defaultPrices[2].price',
        },
        {
          text: 'Kaution überschreiben',
          sortable: false,
          value: 'customPrices[2].price',
        },
      ],
      products: [],
      saving: false,
      loading: true,
    }
  },
  created() {
    this.fetch()
    window.addEventListener('resize', this.updateHeight)
  },
  mounted() {
    this.updateHeight()
  },
  destroyed() {
    window.removeEventListener('resize', this.updateHeight)
  },
  methods: {
    updateHeight() {
      this.tableHeight = document.documentElement.clientHeight - document.getElementById('productTable')
        .getBoundingClientRect().top - 150
    },
    update() {
      this.saving = true
      PricesAPI.updatePrices(this.company.id, { products: this.products })
        .then(() => {
          this.$toasted.success('Preise erfolgreich gespeichert.')
          this.saving = false
          this.fetch()
          this.dirtyStateUnset()
        })
        .catch(() => {
          this.$toasted.error('Hier stimmt etwas nicht. Bitte versuchen Sie es später erneut.')
          this.saving = false
        })
    },
    fetch() {
      this.loading = true
      PricesAPI.getPrices(this.company.id)
        .then((data) => {
          this.products = Object.values(data)
          this.loading = false
          this.dirtyStateWatch('products')
        })
    },
  },
}
</script>

<style lang="scss">
.productImage {
  margin: 5px;
}
</style>
